import clsx from 'clsx';
import { WithImage, WithNopadding, WithOverline, WithText, WithTitle, WithChildren } from '../../withs';
import Button from '../../atoms/Button';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import SectionTitle from '../../atoms/SectionTitle';
import Text from '../../atoms/Text';
import { image } from '@gotombola/types';
import AnimatedImage from '../../atoms/AnimatedImage';

export function ContentLeftPushModule({
    buttons,
    image,
    extraImages,
    nopadding = false,
    overline,
    text,
    title,
    titleCentered,
    children,
}: ContentLeftPushModuleProps) {
    const { mediaShapeClass, blockShapeClass } = useStyling();

    return (
        <div className={clsx('flex flex-col sm:flex-row justify-between', !nopadding && 'p-8')}>
            {!!image?.url && (
                <div className={'w-full sm:w-5/12 mb-4 sm:mb-0'}>
                    <AnimatedImage
                        className={clsx('object-cover w-full h-full', mediaShapeClass)}
                        mainImage={image}
                        maxSize={800}
                        maxSizeTablet={700}
                        maxSizeMobile={500}
                        alt={image.alt || 'image item'}
                        extraImages={extraImages}
                    />
                </div>
            )}
            <div className={clsx('w-full sm:w-6/12 flex flex-col justify-center items-start', !nopadding && 'p-6')}>
                {!!overline && (
                    <div className={'text-sm font-medium text-gray-300 uppercase mb-1'}>
                        <Text>{overline}</Text>
                    </div>
                )}
                <SectionTitle title={title} className={clsx('leading-none mb-4 sm:mb-8', titleCentered && 'mx-auto')} />
                {!!children && children}
                {!!text && (
                    <div
                        className={clsx(
                            'w-full text-md font-light text-gray-600 text-center',
                            text['length'] > 100 && 'sm:text-justify',
                        )}
                    >
                        <Text>{text}</Text>
                    </div>
                )}
                {!!buttons?.length && (
                    <div className={clsx('space-x-4 mt-6 sm:mt-10', titleCentered && 'mx-auto')}>
                        {buttons.map((button: any, index: number) => {
                            return (
                                <Button
                                    className={clsx(
                                        'px-8 py-3 bg-white hover:bg-yellow-500 border-2 border-gray-300 hover:border-transparent text-black hover:text-white text-xl',
                                        blockShapeClass,
                                    )}
                                    key={index}
                                    {...button}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export interface ContentLeftPushModuleProps
    extends Required<WithImage>,
        WithNopadding,
        WithOverline,
        WithText,
        WithTitle,
        WithChildren {
    buttons?: any;
    titleCentered?: boolean;
    extraImages?: image[];
}

export default ContentLeftPushModule;
