import { useMemo } from 'react';
import clsx from 'clsx';
import { WithClassName, WithImage } from '../withs';
import Img from '../atoms/Img';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import { image } from '@gotombola/types';

const dispositions = {
    default: 'object-cover w-full h-full',
    'full-cover': 'object-cover w-full h-full',
    'full-contain': 'object-contain w-full h-full',
    'full-auto': 'w-full h-auto',
};

const bannerSizeClassForcedByDispositions = {
    'full-auto': 'h-auto',
};

const maxModes = {
    default: {
        maxSize: 2048,
        maxSizeMobile: 1280,
        maxSizeTablet: 1536,
    },
    xs: {
        maxSize: 640,
    },
    sm: {
        maxSize: 860,
    },
    md: {
        maxSize: 1024,
    },
    lg: {
        maxSize: 1280,
        maxSizeMobile: 1024,
        maxSizeTablet: 1024,
    },
    xl: {
        maxSize: 1536,
        maxSizeMobile: 1024,
        maxSizeTablet: 1280,
    },
    '2xl': {
        maxSize: 1792,
        maxSizeMobile: 1024,
        maxSizeTablet: 1536,
    },
    '3xl': {
        maxSize: 2048,
        maxSizeMobile: 1280,
        maxSizeTablet: 1536,
    },
    '4xl': {
        maxSize: 2304,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '5xl': {
        maxSize: 2560,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '6xl': {
        maxSize: 2816,
        maxSizeMobile: 1280,
        maxSizeTablet: 1792,
    },
    '7xl': {
        maxSize: 3072,
        maxSizeMobile: 1280,
        maxSizeTablet: 2048,
    },
};

export function Banner({
    className,
    disposition = 'default',
    maxMode = undefined,
    maxSize: forcedMaxSize = undefined,
    maxSizeMobile: forcedMaxSizeMobile = undefined,
    maxSizeTablet: forcedMaxSizeTablet = undefined,
    image = undefined,
    tabletImage = undefined,
    mobileImage = undefined,
    onClick = undefined,
}: BannerProps) {
    const { bannerSizeClass, bannerColorClass } = useStyling();
    const { maxSize, maxSizeMobile, maxSizeTablet } = useMemo(
        () => ({
            ...(maxModes[maxMode || ''] || maxModes['default']),
            ...(forcedMaxSize ? { maxSize: forcedMaxSize } : {}),
            ...(forcedMaxSizeMobile ? { maxSizeMobile: forcedMaxSizeMobile } : {}),
            ...(forcedMaxSizeTablet ? { maxSizeTablet: forcedMaxSizeTablet } : {}),
        }),
        [maxMode, forcedMaxSize, forcedMaxSizeMobile, forcedMaxSizeTablet],
    );

    let content = !!image?.url && (
        <Img
            lazy={false}
            className={clsx(
                dispositions[disposition || ''] || dispositions['default'],
                !!onClick && 'hover:opacity-70',
            )}
            url={image.url}
            tablet={tabletImage}
            mobile={mobileImage}
            alt={image.alt || 'banner'}
            maxMode={maxMode || '3xl'}
            maxSize={maxSize}
            maxSizeMobile={maxSizeMobile}
            maxSizeTablet={maxSizeTablet}
        />
    );
    if (content && onClick && 'string' === typeof onClick) content = <a href={onClick}>{content}</a>;

    return (
        <div
            className={clsx(
                'w-full',
                bannerColorClass,
                bannerSizeClassForcedByDispositions[disposition || ''] || bannerSizeClass,
                className,
            )}
            onClick={onClick && 'function' === typeof onClick ? (onClick as any) : undefined}
        >
            {content}
        </div>
    );
}

export interface BannerProps extends WithClassName, WithImage {
    disposition?: string;
    imageClassName?: string;
    maxMode?: string;
    maxSize?: number;
    maxSizeTablet?: number;
    maxSizeMobile?: number;
    mobileImage?: image;
    tabletImage?: image;
    onClick?: string | Function;
}

// noinspection JSUnusedGlobalSymbols
export default Banner;
